import React from "react"
import {
  BoltIcon,
  FireIcon,
  Square3Stack3DIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline"

const features = [
  {
    name: "Connect",
    description:
      "Invite your team to connect their Facebook, Twitter and LinkedIn accounts.",
    icon: Square3Stack3DIcon,
  },
  {
    name: "Share",
    description:
      "Create campaigns and schedule content to be shared by employees on their connected social accounts.",
    icon: FireIcon,
  },
  {
    name: "Track",
    description:
      "Track clicks, reach and even conversions. View reports and leaderboards to see which employees are performing best.",
    icon: ChartBarIcon,
  },
]

export default function Features() {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="space-y-10 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          {features.map(feature => (
            <div key={feature.name}>
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
